
$color_black: #000;

// ---------------------------------
@mixin txt_link_color($deco:none, $color:$color_black) {
	a,
	a:link,
	a:visited {
		color: $color;
		text-decoration: $deco;
		@include transition(.2);
	}

	@include min_screen(768px) {
		a:hover {
			opacity: .5;
		}
	}
}

@mixin txt_btn($w:600, $h:70) {
	width: 100%;
	max-width: #{$w}px;
	height: #{$h}px;
	@include txt_link_color(none, #fff);
	margin: 0 auto;

	a {
		width: 100%;
		height: 100%;
		display: block;
		background: #000;
		line-height: #{$h}px;
		font-size: 20px;
		letter-spacing: .3em;
		text-indent: .3em;

		@include min_screen(768px) {
			&:hover {
				color: #fff;
				opacity: .5;
			}
		}
	}

	@include max_screen(767px) {
		height: 50px;

		a {
			font-size: 16px;
			line-height: 50px;
		}
	}
}

@mixin cf {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin placeholderColor($color) {
	&:placeholder-shown {
		color: $color;
	}
	&::-webkit-input-placeholder {
		color:$color;
	}
	&:-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&::-moz-placeholder {
		color:$color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}

@mixin transition($time:.3, $prop:all) {
	-moz-transition: $prop #{$time}s ease-in-out;
	-webkit-transition: $prop #{$time}s ease-in-out;
	transition: $prop #{$time}s ease-in-out;
}

@mixin transition2($time:.3, $prop:all) {
	-moz-transition: $prop #{$time}s cubic-bezier(.16,1.46,.62,1.61);
	-webkit-transition: $prop #{$time}s cubic-bezier(.16,1.46,.62,1.61);
	transition: $prop #{$time}s cubic-bezier(.16,1.46,.62,1.61);
}

@mixin min_screen($break-point) {
	@media screen and (min-width: $break-point) {
		@content;
	}
}

@mixin max_screen($break-point) {
	@media screen and (max-width: $break-point) {
		@content;
	}
}