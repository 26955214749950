@import "var";

//feature -----------------------------------------------
.feature{
	box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.35);
	padding-bottom: 57px;
	margin-bottom: 37px;

	&_inner{
		margin: 0 auto;
		width: percentage(650px / 750px);
		position: relative;
	}

	&_ttl{
		font-size: 18px;
		letter-spacing: 0.1em;
		text-indent: 0.1em;
		padding-top: 5px;
		padding-bottom: 8px;
		margin-bottom: 21px;
		line-height: 1;
		border-bottom: 1px dotted #cfcfcf;
	}

	.movie{
		margin-bottom: 33px;

		&_main{
			margin-bottom: 25px;
		}

		&_sub-ttl{
			font-size: 11px;
			letter-spacing: 0.1em;
			margin-bottom: 5px;
			text-align: left;
		}

		&_link{
			text-align: left;

			dl{
				display: flex;
				margin-bottom: 21px;

				&:last-child{
					margin-bottom: 0;
				}

				&.off {
					color: #bbb;
					pointer-events: none;

					dt{
						&:after{
							background: #bbb;
						}
					}
				}

				dt{
					width: 55px;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0.1em;
					line-height: 1;
					padding-right: 8px;
					box-sizing: border-box;
					position: relative;

					&:after{
						content: "";
						width: 1px;
						height: 15px;
						display: block;
						position: absolute;
						right: 0;
						top: 0;
						background-color: #000;
					}
				}

				dd{
					font-size: 16px;
					font-weight: 500;
					letter-spacing: 0.1em;
					line-height: percentage(56 / 32);
					padding-left: 12px;
					box-sizing: border-box;
					margin-top: -7px;

					a{
						&:hover,
						&:visited,
						&:link{
							color: #000;
						}

						&:hover{
							transition: all 0.2s ease-in-out;
							opacity: 0.7;
						}
					}
				}
			}
		}
	}//movie

	.article{
		text-align: left;
		width: 100%;
		z-index: 1;
		@include txt_link_color;
		position: relative;

		.sub-ttl{
			font-size: 11px;
			letter-spacing: 0.1em;
			margin-bottom: 6px;
		}

		&_number{
			margin-bottom: 22px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}

		a {
			display: block;
		}

		&_item{
			display: flex;
		}

		&_img{
			width: percentage(310px / 650px);
		}

		&_txt{
			padding-left: 25px;
			box-sizing: border-box;
			width: percentage(340px / 650px);

			.ttl{
				font-size: 16px;
				letter-spacing: 0.1em;
				line-height: percentage(42 / 32);
				margin-bottom: 6px;
			}

			.txt{
				font-size: 12px;
				letter-spacing: 0.1em;
				line-height: percentage(34 / 24);
				box-sizing: border-box;
			}

			.ttl_vol2{
				padding-top: 13px;
			}

			.ttl_vol1{
				padding-top: 32px;
			}
		}
	}// article

	.special-event{
		margin-bottom: 47px;
		@include txt_link_color;

		a {
			display: block;
		}

		&_main{
			margin-bottom: 21px;
		}

		&_ttl{
			font-size: 16px;
			letter-spacing: 0.05em;
			text-align: left;
			margin-bottom: 22px;
			line-height: percentage(46 / 32);
			text-decoration: underline;

			rt {
				letter-spacing: 0;
				text-indent: 0;
				//display: none;
				transform: translateY(5px);
			}
		}

		&_sub-ttl{
			font-size: 11px;
			letter-spacing: 0.1em;
			text-align: left;
			line-height: 1;
			margin-bottom: 9px;
		}

		&_txt{
			font-size: 12px;
			letter-spacing: 0.1em;
			line-height: percentage(30 / 24);
			text-align: left;

			.day1{
				display: block;
				margin-bottom: 15px;
			}

			.day2{
				display: block;
			}
		}
	}//special-event
}

//news-----------------------------------------------
.news{
	box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.35);
	padding-bottom: 57px;
	margin-bottom: 38px;

	&_inner{
		margin: 0 auto;
		width: percentage(650px / 750px);
	}

	&_ttl{
		font-size: 18px;
		letter-spacing: 0.1em;
		margin-bottom: 35px;
		border-bottom: 1px dotted #a0a0a0;
		padding-bottom: 8px;
	}

	&_txt{
		font-size: 14px;
		letter-spacing: 0.05em;
		line-height: percentage(48 / 28);
		margin-bottom: 32px;
		text-align: left;

		&:last-of-type{
			margin-bottom: 0;
		}

		a{
			&:hover,
			&:visited,
			&:link{
				color: #000;
			}

			&:hover{
				transition: all 0.2s ease-in-out;
				opacity: 0.7;
			}
		}
	}
}

//about-----------------------------------------------
.about{
	box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.35);
	padding-bottom: 57px;
	margin-bottom: 38px;

	&_inner{
		margin: 0 auto;
		width: percentage(600px / 750px);
	}

	&_ttl{
		font-size: 18px;
		letter-spacing: 0.1em;
		text-indent: 0.1em;
		text-align: center;
		margin-bottom: 28px;
	}

	&_catch{
		font-size: 32px;
		letter-spacing: 0.075em;
		text-indent: 0.075em;
		text-align: left;
		margin-bottom: 18px;

		> span{
			display: inline-block;
			width: 23px;
			position: relative;

			span{
				position: absolute;
				left: -2px;
				bottom: -11px;
			}
		}

		.marks{
			display: inline-block;
			width: 4px;
			position: relative;
		}
	}

	&_txt{
		font-size: 13px;
		margin: 0 auto;
		letter-spacing: 0.1em;
		text-align: left;
		font-weight: 500;
		line-height: percentage(48 / 26);

		span{
			font-size: 15px;
			letter-spacing: 0.1em;
			text-align: left;
			display: block;
			margin-top: 24px;
		}
	}
}

//member-----------------------------------------------
.member {
	width: 100%;
	padding: 10px 40px 100px;
	box-sizing: border-box;
	margin-top: -10px;


	&_inner {
		max-width: 977 + 40 * 2px;
		margin: 0 auto;
		position: relative;
	}

	&_ttl {
		font-size: 32px;
		line-height: 1;
		letter-spacing: .3em;
		text-indent: .3em;
		margin-bottom: 46px;

		.en {
			font-size: 36px;
		}
	}

	&_img {
		margin-bottom: 53px;
	}

	&_txt {
		font-size: 14px;
		letter-spacing: .1em;
		line-height: percentage(34 / 14);
		margin-bottom: 45px;
	}

	&_merit {
		width: 600px;
		margin: 0 auto 32px;
		font-size: 14px;

		dt {
			font-size: 16px;
			font-weight: 700;
			line-height: 1;
			margin-bottom: 28px;
			letter-spacing: .3em;
			text-indent: .3em;
		}

		dd {
			padding: 25px 40px;
			background: url("/assets/img/index/line_bg.gif") repeat-x left top,
						url("/assets/img/index/line_bg.gif") repeat-x left bottom;
			text-align: left;
			letter-spacing: .05em;

			ul {
				width: 100%;

				li {
					margin-bottom: 13px;
					line-height: percentage(24 / 14);

					&:last-child {
						margin-bottom: 0;
					}

					.no {
						display: inline-block;
						padding-right: .5em;
					}

					.event_txt {
						display: block;
						padding-top: 10px;
						margin-left: 2em;

						.space {
							display: inline;
						}
					}
				}
			}
		}
	}

	&_btn {
		@include txt_btn;
	}

	@include max_screen(767px) {

		padding: 10px 0 37px;

		&_inner {
			width: 100%;
		}

		&_ttl {
			font-size: 19px;
			margin-bottom: 23px;
			letter-spacing: 0.3em;
			text-indent: 0.3em;

			.en {
				font-size: 20px;
			}
		}

		&_img {
			margin-bottom: 33px;
		}

		&_txt {
			font-size: 13px;
			text-align: left;
			width: percentage(600 / 750);
			margin: 0 auto 20px;
			letter-spacing: .05em;
			line-height: percentage(46 / 26);
		}

		&_merit {
			font-size: 13px;
			width: percentage(600 / 750);
			margin: 0 auto 11px;
			text-align: left;
			letter-spacing: .05em;

			dt {
				font-size: 13px;
				font-weight: 700;
				line-height: 1;
				margin-bottom: 14px;
				letter-spacing: .2em;
				text-indent: 0;
			}

			dd {
				padding: 15px 0;
				text-align: left;
				letter-spacing: .05em;

				ul {
					width: 100%;

					li {
						margin-bottom: 3px;

						&:last-child {
							margin-bottom: 0;
						}

						.no {
							display: inline-block;
							padding-right: .2em;
						}

						.event_txt {
							display: block;
							padding: 24px 0 24px 0;
							margin-left: 0;
						}

						.space {
							display: none;
						}
					}
				}
			}
		}

		&_btn {
			width: percentage(600 / 750);

			span {
				display: none;
			}
		}
	}
}

// modal --------------------------------
.modal__bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255,.9);
    z-index: 10000;
	display: none;
}

.modal__window {
	box-shadow: 0 0 5px rgba(0,0,0,.2);
	position: absolute;
    top: 20%;
    left: percentage(25px / 750px);
    z-index: 10000;
    background: #fff;
	width: percentage(700px / 750px);
	padding: 32px 25px;
	box-sizing: border-box;
	display: none;

	&.movie {
		padding: 0;
		background: none;
		box-shadow: none;

		iframe {
			vertical-align: top;
			box-shadow: 0 0 5px rgba(0,0,0,.2);
		}
	}
}

.modal__window .modal__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

#modal1{

	.modal__inner{
		.ttl{
			font-size: 16px;
			letter-spacing: 0.2em;
			text-indent: 0.2em;
			text-align: center;
			margin-bottom: 40px;
			line-height: percentage(52 / 32);
		}

		.time{
			font-size: 14px;
			letter-spacing: 0.05em;
			text-indent: 0.05em;
			margin-bottom: 20px;
		}

		.txt{
			font-size: 14px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			text-align: left;
			line-height: percentage(48 / 28);
		}
	}
}

#modal2{

	.modal__inner{
		.ttl{
			font-size: 16px;
			letter-spacing: 0.2em;
			text-indent: 0.2em;
			text-align: center;
			margin-bottom: 35px;
			line-height: percentage(52 / 32);
		}

		.time{
			font-size: 14px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			margin-bottom: 20px;
			line-height: percentage(48 / 28);
		}

		.txt{
			font-size: 14px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			text-align: left;
			line-height: percentage(48 / 28);
		}
	}
}

#modal3{

	.modal__inner{
		.ttl{
			font-size: 16px;
			letter-spacing: 0.2em;
			text-indent: 0.2em;
			text-align: center;
			margin-bottom: 27px;
			line-height: percentage(52 / 32);
		}

		.time{
			font-size: 14px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			margin-bottom: 20px;
			line-height: percentage(48 / 28);

			small {
				display: block;
				margin-top: 11px;
				font-size: 13px;
			}
		}

		.txt{
			font-size: 14px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			text-align: left;
			line-height: percentage(48 / 28);
		}
	}
}

#modal4{

	.modal__inner{
		.ttl{
			font-size: 16px;
			letter-spacing: 0.2em;
			text-indent: 0.2em;
			text-align: center;
			margin-bottom: 35px;
			line-height: percentage(52 / 32);
		}

		.time{
			font-size: 14px;
			letter-spacing: 0.05em;
			margin-bottom: 20px;
			line-height: percentage(48 / 28);
			text-align: left;

			span{
				display: block;
			}
		}

		.img{
			margin-bottom: 25px;
		}

		.txt{
			font-size: 14px;
			letter-spacing: 0.1em;
			text-indent: 0.1em;
			text-align: left;
			line-height: percentage(48 / 28);
		}
	}
}
